import React from "react"
import Layout from "../layout"
import Seo from "../seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { withTrans } from "../../i18n/withTrans"

const Person = ({t, ...props}) => {
  const {
    pageContext: {
        abschluss,
        abteilungen,
        diplomprakt,
        diplomtheo,
        nachname,
        lebensdaten,
        vorname,
        zeitraum,
        node,
        next,
        previous,
    },
  } = props
    return (
        <Layout>
            <Seo title={vorname+" "+nachname}/>
                <main id="maincontent">
                    <div style={{marginTop: "100px", marginBottom: "60px", minHeight:"100px"}}>
                        <GatsbyImage image={getImage(node)} style={{width:"200px", margin: "0 auto", display:"block"}}/>
                    </div>
                    <div style={{background: "#ffffff", padding:"20px"}}>
                        <div style={{paddingLeft:"max(calc(50vw - 250px), 20px)", paddingRight: "max(calc(50vw - 250px), 20px)", marginBottom:"0"}}>
                            <p className="p11G" style={{paddingTop:"50px"}}>{nachname}, {vorname}<br/>({lebensdaten})</p>
                            <p className="p11B"><br/> {t("person.zeit")} </p>
                            <p className="p11G">{zeitraum}</p>
                            <p className="p11B"><br/>{t("person.abteilung")}</p>
                            <p className="p11G">{abteilungen}</p>
                            <p className="p11B"><br/>{t("person.abschluss")}</p>
                            <p className="p11G">{abschluss}</p>
                            <p className="p11B"><br/>{t("person.dipltheo")}</p>
                            <p className="p11G">{diplomtheo}</p>
                            <p className="p11B"><br/>{t("person.diplprak")}</p>
                            <p className="p11G" style={{paddingBottom:"50px"}}>{diplomprakt}</p>

                            <p className="p11B" style={{marginTop:"40px", paddingBottom:"15px"}}>
                            { previous &&
                              <Link to={"/personen/"+previous.vorname.toLowerCase() + `-` + previous.nachname.toLowerCase()}
                                style={{textDecoration: "underline", color:"black", float:"left"}}>
                                  {t("person.prev")}
                              </Link>
                            }
                            { next &&
                              <Link to={"/personen/"+next.vorname.toLowerCase() + `-` + next.nachname.toLowerCase()}
                                style={{textDecoration: "underline", color:"black", float:"right"}}>
                                  {t("person.next")}
                              </Link>
                            }
                            </p>
                            <br/>
                        </div>
                    </div>
                </main>
        </Layout>
    )
}

export default withTrans(Person)
